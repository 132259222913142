import Vue from "vue";
import VueRouter from "vue-router";
import { getLoginStatus, getUserRole } from "@/api/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/admin/organization",
  },
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "../views/login/index.vue"),
  },
  {
    path: "/verify",
    name: "verify",
    component: () => import(/* webpackChunkName: "login" */ "../views/login/verify.vue"),
  },
  {
    path: "/first_login_update_password",
    name: "firstLoginUpdatePassword",
    component: () => import(/* webpackChunkName: "login" */ "../views/login/first_login_update_password.vue"),
  },
  {
    path: "/admin",
    redirect: "/admin/organization",
    component: () => import(/* webpackChunkName: "organization" */ "../components/layout/index.vue"),
    children: [
      {
        path: "organization",
        name: "organization",
        title: "组织",
        component: () => import(/* webpackChunkName: "organization" */ "../views/organization/index.vue"),
      },
      {
        path: "user",
        name: "user",
        title: "用户",
        component: () => import(/* webpackChunkName: "user" */ "../views/user/index.vue"),
      },
      {
        path: "equipment",
        name: "equipment",
        title: "设备",
        component: () => import(/* webpackChunkName: "equipment" */ "../views/equipment/index.vue"),
      },
    ],
  },
  {
    path: "/404",
    name: "network-not-found",
    component: () => import(/* webpackChunkName: "notfound" */ "../404.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log("to————————————————————", to);
  console.log("from_______________", from);
  const res = await getLoginStatus();
  console.log("status ========================", res.loginStatus);
  if (to.path === "/login") {
    next();
  } else {
    if (res.loginStatus) {
      next();
    } else {
      next("/login");
    }
  }
});

export default router;
